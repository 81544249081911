import {
  HelpOutlineOutlined as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'Help',
  title: 'Help',
  category: 'Utils',
  exposes: [
    {
      title: 'Interface',
      action: { module: 'Help', props: { context: 'Interface' } },
      desription: 'Interface-desc',
    },
    {
      title: 'Glossary',
      action: { module: 'Help', props: { context: 'Glossary' } },
      desription: 'Glossary-desc',
    },
    {
      title: 'Tools',
      action: { module: 'Help', props: { context: 'Tools' } },
      desription: 'Tools-desc',
    },
    {
      title: 'Integrations',
      action: { module: 'Help', props: { context: 'Integrations' } },
      desription: 'Integrations-desc',
    },
    {
      title: 'FAQ',
      action: { module: 'Help', props: { context: 'FAQ' } },
      desription: 'FAQ-desc',
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `Shows help contents for users`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
