import { gql } from 'utils/graphql/';

const userSettings = gql`
  query {
    userSettings {
      workspaces,
      customizations,
    }
  }
`;

export { userSettings };
