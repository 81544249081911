import { gql } from 'utils/graphql/';

const userSettings = gql`
  query {
    userSettings {
      workspaces,
      customizations,
    }
  }
`;
const findAllProducts = gql`
  query {
    products {
      id
      name
      productData
    }
  }
`;


export { userSettings, findAllProducts };
