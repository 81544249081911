import * as React from 'react';

const SvgCalculateOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="CalculateOutlined_svg__MuiSvgIcon-root CalculateOutlined_svg__MuiSvgIcon-fontSizeMedium CalculateOutlined_svg__MuiSvgIcon-fontSizeLarge CalculateOutlined_svg__css-1shn170"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CalculateOutlinedIcon"
    tabIndex={-1}
    data-icon="1"
    {...props}
  >
    <path
      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"
      fill="#FFF"
    />
    <path
      d="M6.25 7.72h5v1.5h-5zM13 15.75h5v1.5h-5zm0-2.5h5v1.5h-5zM8 18h1.5v-2h2v-1.5h-2v-2H8v2H6V16h2zm6.09-7.05 1.41-1.41 1.41 1.41 1.06-1.06-1.41-1.42 1.41-1.41L16.91 6 15.5 7.41 14.09 6l-1.06 1.06 1.41 1.41-1.41 1.42z"
      fill="#FFF"
    />
  </svg>
);

export default SvgCalculateOutlined;
