import {
  CalendarMonthOutlined as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'Tools',
  title: 'Calendar',
  category: 'Utils',
  exposes: [
    {
      title: 'Calendar',
      action: { module: 'Calendar', props: { /* */ } },
      desription: 'general-desc',
    },
    {
      title: 'Calculator',
      action: { module: 'Calculator', props: { /* */ } },
      desription: 'general-desc',
    },
    {
      title: 'QuickNote',
      action: { module: 'QuickNote', props: { /* */ } },
      desription: 'general-desc',
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `A basic calendar tool.`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 350,
    height: 360
  }
};
