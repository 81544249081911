import React from 'react';
import { Icon } from 'semantic-ui-react';

const IconCalc = () => <Icon name='calculator' />;

export const meta = {
  title: 'Calculator',
  category: 'Utils',
  unique: false,
  icon: IconCalc,
  version: '1.0.0',
  description: `Used to quick calculations.`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    resizable: false,
    width: 310,
    height: 505
  }
};
