import {
  Note as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'QuickNote',
  title: 'QuickNote',
  category: 'Utils',
  // exposes: [
  //   {
  //     title: 'General',
  //     action: { module: 'QuickNote', props: { /* */ } },
  //     desription: 'general-desc',
  //   },
  // ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `Allows the user to write a quick, local, temporary note.`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 650,
    height: 415
  }
};
