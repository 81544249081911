import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'NewsCategories',
  title: 'NewsCategories',
  category: 'News',
  exposes: [
    {
      title: 'newsCategory',
      action: { module: 'NewsCategories', props: { /* */ } },
    },
    {
      title: 'New newsCategory',
      action: { module: 'NewsCategoriesDetails', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `Module to register the news category`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
