import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'ServiceItemsDetails',
  title: 'ServiceItemsDetails',
  category: 'Services',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `crud for service items`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 640,
    height: 670
  }
};
