import {
  Specs as Icon
} from 'components/icons';

export const meta = {
  id: 'Specs',
  title: 'Specs',
  category: 'Vehicles',
  exposes: [
    {
      title: 'Specs',
      action: { module: 'Specs', props: { /* */ } },
    },
    {
      title: 'New Specs',
      action: { module: 'SpecsDetails', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `The technical specs for vehicles.`,
  windowOptions: {
    // if not passed, will be centered
    x: 100,
    y: 100,
    // width: 630,
    // height: 380
  }
};
