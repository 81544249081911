
export default function gql (literal, ...args) {
  function normalize (string) {
    return string
      .replace(/#[^\n]+/g, ' ')
      .replace(/[\s,]+/g, ' ').trim();
  }

  function parseArg (arg) {
    if (Number.isFinite(arg)) {
      // is just a regular number
      return arg;
    }

    if (typeof arg === 'string') {
      return `"${arg}"`;
    }

    if (arg === undefined) {
      return '';
    }

    if (arg === true || arg === false) {
      return arg.toString();
    }

    return JSON.stringify(arg);
  }

  const parts = literal.map((part, i) => {
    return part + parseArg(args[i]);
  });

  return normalize(parts.join());
}

export function getGQLFragment (fields, on, opts = { name: 'Parts'}) {
  return `
    fragment ${opts.name || 'Parts'} on ${on} {
      ${fields.join(',')}
    }
  `;
}

export function getGQLSet (parent, children) {
  return `
    ${parent} {
      ${children}
    }
  `;
}

export function getGQLFieldsFrom (fields = [], obj, opts = { ignore: [] }) {
  const retObj = {};
  fields.forEach(field => {
    if (opts?.ignore?.includes(field)) return;
    return retObj[field] = obj[field];
  });
  return retObj;
}
