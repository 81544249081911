import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'Taxonomies',
  title: 'Taxonomies',
  category: 'Taxonomy',
  exposes: [
    {
      title: 'taxonomy',
      action: { module: 'Taxonomies', props: { /* */ } },
    },
    {
      title: 'New taxonomy',
      action: { module: 'TaxonomiesDetails', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `module for taxonomies`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 830,
    height: 680
  }
};
