import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  title: 'FilePicker',
  category: 'Utils',
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `A file picker to select files, paste from clipboard or even drop a file`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
