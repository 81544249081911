import {
  Info
} from 'components/icons';

export const meta = {
  id: 'About',
  title: 'About',
  category: 'Utils',
  // group: 'General',
  exposes: [
    {
      title: 'System',
      action: { module: 'About', props: {} },
      desription: 'general-settings-desc',
    },
  ],
  unique: true,
  icon: Info,
  // titlePrefix: "About",
  // titleSufix: "About",
  version: '1.0.0',
  description: "A screen abbout the current version of the project",
  windowOptions: {
    width: 630,
    height: 380
  }
};
