import { Accessories as Icon } from 'components/icons';

export const meta = {
  id: 'AccessoryCategories',
  title: 'AccessoryCategories',
  category: 'Vehicles',
  // exposes: [
  //   {
  //     title: 'accessory categories',
  //     action: {
  //       module: 'AccessoryCategories',
  //       props: {
  //         /* */
  //       }
  //     }
  //   },
  //   {
  //     title: 'New accessory categories',
  //     action: { module: 'AccessoryCategoriesDetails',
  //       props: { context: 'add' } }
  //   }
  // ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `category for accessories`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
