import * as React from 'react';

const SvgCodeOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="CodeOutlined_svg__MuiSvgIcon-root CodeOutlined_svg__MuiSvgIcon-fontSizeMedium CodeOutlined_svg__MuiSvgIcon-fontSizeLarge CodeOutlined_svg__css-1shn170"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CodeOutlinedIcon"
    tabIndex={-1}
    data-icon="1"
    {...props}
  >
    <path
      d="M9.4 16.6 4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0 4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"
      fill="#FFF"
    />
  </svg>
);

export default SvgCodeOutlined;
