import React from 'react';
import Head from 'next/head';

import Layout from 'utils/hocs/layout';
import { applyContexts } from 'utils/providers';
import { ToastContainer } from 'react-toastify';


import 'react-toastify/dist/ReactToastify.min.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-calendar/dist/Calendar.css';
import 'utils/styles/_reset.scss';

function MyApp({ Component, pageProps }) {
  return applyContexts(
    <Layout>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link rel="shortcut icon" href="/toyota-amos-favicon.png" />
      </Head>
      <Component {...pageProps} />

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>,
    { pageProps }
  );
}

export default MyApp;
