import { Info as Icon } from 'components/icons';

export const meta = {
  id: 'EventEditions',
  title: 'EventEditions',
  category: 'Event',
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `module to register the event edition`,
  windowOptions: {
    width: 1000,
    height: 500
  }
};
