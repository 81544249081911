import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'Menus',
  title: 'Menus',
  category: 'Pages',
  exposes: [
    {
      title: 'menu',
      action: { module: 'Menus', props: { /* */ } },
    },
    {
      title: 'New menu',
      action: { module: 'MenusDetails', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `manage menus `,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
