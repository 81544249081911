import React, {
  createContext,
  useContext,
  useState,
  // useEffect
} from 'react';
import modules from 'modules/index';
import getConfig from 'next/config';
import { debounce } from 'utils/functions';
import { doMutation, doQuery } from 'utils/graphql';
import { upsertUserSettings } from './mutations';
import { userSettings } from './queries';

const { publicRuntimeConfig } = getConfig();

const isDev = publicRuntimeConfig.ENV_TYPE === 'DEV';

const WindowsContext = createContext();
let currentZIndex = 0;

function WindowsProvider ({ children }) {
  // whatever you set here as default, will be used in both server side and client as well

  const allAvailabble = modules.available;
  const modsAvailable =  isDev ? allAvailabble : {};

  if (!isDev) {
    // if env is not in development mode, we will hide some modules
    // some modules may actually be in hml
    for (let modId in allAvailabble) {
      // remove all components in category dev
      if (
        allAvailabble[modId].category.toLowerCase() === 'dev'
      ) { continue; }
      modsAvailable[modId] = allAvailabble[modId];
    }
  }

  const [providerData, setProviderData] = useState({
    ready: false,
    data: {
      // add default data to your provider here
      active: null,
      open: [],
      // ...storedState,
      available: modsAvailable
    }
  });

  // you can get extra data asynchronously if needed
  // this will only affect the client side
  async function fetchProviderData () {
    return doQuery(userSettings)
      .then(result => {
        result?.data?.userSettings?.workspaces?.open?.forEach((w, i) => {
          setTimeout(_ => {
            value.openWindow(w.type, w.props, w.windowOptions);
          }, i * 250);
        });
      })
      .catch(err => console.debug('Error at WindowsProvider:', err));
  }

  React.useEffect(() => { fetchProviderData(); }, []);

  const value = {
    ...providerData,
    setData (key, value) {
      const newData = {
        ...providerData,
        data: {
          ...providerData.data,
          [key]: value
        }
      };
      setProviderData(newData);
    }
  };

  value.setFocus = function (id) {
    setProviderData(curData => {
      const newData = {...curData};
      newData.data.open = curData.data.open.map(w => {
        if (w.id === id) {
          w.minimized = false;
          currentZIndex++;
          w.order = currentZIndex;
        }
        return w;
      });
      newData.data.active = id;
      return newData;
    });
  };


  React.useEffect(_ => {
    function keydownListener (event) {
      if (event.altKey && event.metaKey) {
        setProviderData(curData => {
          const newData = {...curData};
          newData.data.debug = true;
          return newData;
        });
      }
    }

    function keyUpListener (event) {
      if (event.keyCode === 18) {
        setProviderData(curData => {
          const newData = {...curData};
          newData.data.debug = false;
          return newData;
        });
      }
    }

    if (isDev) {
      document.body.addEventListener('keydown', keydownListener);
      document.body.addEventListener('keyup', keyUpListener);

      return _ => {
        document.body.removeEventListener('keydown', keydownListener);
        document.body.removeEventListener('keyup', keyUpListener);
      };
    }
  }, []);

  function getWindowById (id, list) {
    list = list || value;
    return list.data.open.find(item => item.id === id) || null;
  }

  value.minimizeWindow = function (id) {
    setProviderData(curData => {
      const unminimized = [];
      const newOpenList = curData.data.open.map(w => {
        if (w.id === id) {
          w.minimized = true;
        } else if (!w.minimized) {
          unminimized.push(w);
        }
        return w;
      });

      let nextWinToFocus = null;
      unminimized.forEach(win => {
        if (win.id === id) { return; }
        if (!nextWinToFocus || nextWinToFocus.order < win.order) {
          nextWinToFocus = win;
        }
      });

      nextWinToFocus = nextWinToFocus?.id || null;

      const newData = {...curData};
      newData.data.open = newOpenList;
      newData.data.active = nextWinToFocus;
      return newData;
    });
    // value.setData('open', newOpenList);
    // value.setData('active', nextWinToFocus);
  };

  value.closeWindow = function (id) {
    setProviderData(curData => {
      const newData = {...curData};
      let spawned = null;
      let nextActive = null;
      let currentOpen = curData.data.open.filter(item => {
        if (item.id === id) {
          spawned = item.spawned;

          if (item.parent) {
            const parentWindow = getWindowById(item.parent, curData);
            if (parentWindow?.tiedTo === item.id) {
              // changing by reference
              // will untie parent window
              parentWindow.tiedTo = null;
              nextActive = parentWindow;
            }
          }
          return false;
        }
        return true;
      });

      if (spawned && spawned.length) {
        // closes all child windows with it
        currentOpen = currentOpen.filter(item => {
          // if (item.order > (biggerOrder.order || 0)) {
          //   biggerOrder = item;
          // }
          return !spawned.includes(item.id);
        });
      }

      // find the next window to be focused
      if (nextActive) {
        currentZIndex++;
        nextActive.order = currentZIndex;
      } else {
        currentOpen.forEach(item => {
          if (item.order > (nextActive?.order || 0)) {
            nextActive = item;
          }
        });
      }

      // if (!nextActive && currentOpen.length) {
      //   nextActive = biggerOrder?.id || null;
      // }

      newData.data.open = currentOpen;
      newData.data.active = nextActive?.id || null;
      return newData;
    });
  };

  value.saveCurrentWindows = async function saveCurrentWindows () {
    const wins = [];
    providerData.data.open.forEach(openW => {
      const pos = openW.props.apis.window.getPosition();
      const size = openW.props.apis.window.getSize();
      if (pos) {
        openW.windowOptions = {
          ...openW.windowOptions,
          ...pos,
          ...size
        }; // update with current window's position and size
        wins.push(openW);
      }
    });

    const newData = {
      data: {
        workspaces: {
          active: providerData.data.active,
          open: wins
        }
      }
    };

    sendCurrentWindows(newData);
  };

  const sendCurrentWindows = debounce(async (newData) => {
    await doMutation(upsertUserSettings, newData);
  },500);

  value.openWindow = function openWindow(type, props = {}, options = {}) {
    return new Promise((resolve, reject) => {
      setProviderData(curData => {
        const windowToOpen = curData.data.available[type];

        const {
          windowOptions:optsWindowOptions,
          ...otherOptions
        } = options;

        const windowOptions = {
          ...(windowToOpen?.windowOptions || {}),
          ...(optsWindowOptions || {}),
          ...otherOptions
        };

        if (!windowToOpen) {
          const msg = `[W_ERR] :: Invalid module type "${type}"`;
          reject(msg);
          console.error(msg);
          return curData;
        }

        let id = null;
        const currentOpen = curData.data.open;
        const hasInstances = curData.data.open.filter(
          item => item.type === type
        );

        if (windowToOpen.unique && hasInstances.length) {
          // only focus the last one
          id = hasInstances[0].id;
          hasInstances[0].order = currentZIndex;
        } else {
          // actually opens a window
          id = currentOpen.length + "" + (new Date()).getTime();
        }

        currentZIndex++;
        if (windowToOpen.unique && hasInstances.length) {
          // only focus the last one
          id = hasInstances[0].id;
          hasInstances[0].order = currentZIndex;
          resolve(id);
        } else {
          // actually opens a window
          id = currentOpen.length + "" + (new Date()).getTime();

          props.apis = {
            window: {
              id,
              spawn (...args) {
                return spawn(id, ...args);
              },
              navigate (type, data = {}, options = {resize: true}) {
                setProviderData(curData => {
                  const windowToOpen = curData.data.available[type];

                  if (!windowToOpen) {
                    console.error(`[win] :: Could not find a ${type} module to navigate to`);
                    return;
                  }

                  const newData = {...curData};
                  const currentOpen = curData.data.open;
                  const theWindow = currentOpen.find(openW => openW.id === id);
                  theWindow.type = type;
                  theWindow.fileName = windowToOpen.fileName;

                  if (options.resize) {
                    const curWindowAPI = theWindow.props.apis.window;
                    if (!curWindowAPI.getDOMElement()?.dataset.isMax) {
                      // const {
                      //   x:prevWinX,
                      //   y:prevWinY,
                      //   width: prevWinW
                      // } = curWindowAPI.getDimensions();
                      // const {
                      //   x: nextWinX = prevWinX,
                      //   width: nextWinW = prevWinW
                      // } = windowToOpen.windowOptions;

                      // const adjustedX = Math.round(
                      //   nextWinX + (
                      //     Math.max(prevWinW, nextWinW) -
                      //     Math.min(prevWinW, nextWinW)
                      //     / 2)
                      // );

                      // windowToOpen.windowOptions.x = adjustedX;
                      // windowToOpen.windowOptions.y = prevWinY;

                      const prevWindowOptions = {
                        ...(theWindow.windowOptions || {})
                      };
                      theWindow.windowOptions = {
                        ...prevWindowOptions,
                        ...(windowToOpen.windowOptions || {}),
                      };
                    }
                  }

                  theWindow.history = theWindow.history || new WindowHistory();
                  theWindow.history.push(
                    type,
                    {
                      ...(windowToOpen.props || {}),
                      ...data,
                      windowOptions: windowToOpen.windowOptions
                    }
                  );

                  theWindow.props = {
                    history: theWindow.history,
                    loading: true,
                    apis: props.apis,
                    ...(windowToOpen.props || {}),
                    ...data
                  };

                  newData.data.open = [...currentOpen];
                  return newData;
                });
              }
            },
            ui: {}
          };

          currentOpen.push({
            id,
            type,
            fileName: windowToOpen.fileName || type,
            minimized: false,
            order: currentZIndex,
            props,
            windowOptions,
            history: new WindowHistory(
              type,
              {
                ...(windowToOpen.props || {}),
                ...props,
                windowOptions
              }
            ),
            spawned: [],
            tiedTo: null,
            ...otherOptions
          });
        }

        const newData = {...curData};
        newData.data.open = currentOpen;
        newData.data.active = id;
        setTimeout(() => {
          resolve(id);
        }, 10);
        return newData;
      });
      // value.setData('open', currentOpen);
      // value.setData('active', id);
    });
  };

  async function spawn (id, type, props = {}, options = {}) {
    const createdId = await value.openWindow(
      type,
      props,
      { parent: id, ...options}
    );

    setProviderData(curData => {
      const newData = {...curData};
      const parentIdx = newData.data.open.findIndex(i => i.id === id);
      newData.data.open[parentIdx].spawned.push(createdId);

      if (options.tied) {
        newData.data.open[parentIdx].tiedTo = createdId;
      }

      return newData;
    });
  }

  value.spawn = spawn;

  // value.saveEnvironment = function saveEnvironment () {
  //   new Promise((resolve, reject) => {
  //     // todo: aqui // aqui
  //     localStorage.setItem('currentWindows', JSON.stringify({
  //       active: value.data.active,
  //       open: value.data.open
  //     }));
  //     resolve();
  //   });
  // };

  // value.loadEnvironment = function saveEnvironment () {
  //   new Promise((resolve, reject) => {
  //     // todo: aqui // aqui
  //     localStorage.setItem('currentWindows', JSON.stringify({
  //       active: value.data.active,
  //       open: value.data.open
  //     }));
  //     resolve();
  //   });
  // };

  return (
    <WindowsContext.Provider value={value}>
      {children}
    </WindowsContext.Provider>
  );
}

// this allows you to use it as a simpler hook
function useWindows () {
  const context = useContext(WindowsContext);
  if (context === undefined) {
    throw new Error('useWindows must be used within a WindowsProvider');
  }

  return context;
}

export {
  WindowsProvider,
  WindowsContext,
  useWindows
};

function WindowHistory (firstItem, firstData) {
  const histList = [];
  let cursor = -1;

  this.push = (item, data) => {
    histList.splice(cursor + 1); // will replace the forward history
    histList.push({item, data});
    cursor = histList.length - 1;
    return histList[cursor];
  };

  this.back = (otherProps = {}) => {
    if (cursor === -1) {
      return false;
    }
    cursor--;
    const ret = histList[cursor];
    return [ret.item, {...ret.data, ...otherProps}];
  };

  this.forward = () => {
    if (cursor === histList.length - 1) {
      return false;
    }
    cursor++;
    const ret = histList[cursor];
    return [ret.item, ret.data];
  };

  this.canBack = function () {
    return !!histList[cursor];
  };

  this.canForward = function () {
    return !!histList[cursor + 1];
  };

  if (firstItem) {
    this.push(firstItem, firstData);
  }

  return this;
}