export function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" +
    // eslint-disable-next-line
    name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
    "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

// how to set
// setCookie('user', 'John', {secure: true, 'max-age': 3600});
export function setCookie(name, value, options = {}) {
  options = {
    path: '/',
    // add other defaults here if necessary
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) +
    "=" +
    encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  });
}

export function setCookies (cookies, options = {}) {
  for (let name in cookies) {
    const value = cookies[name];
    setCookie(name, value, options);
  }
}
