import React from 'react';
import { Icon } from 'semantic-ui-react';

const IconItem = () => <Icon name="window maximize outline" />;

export const meta = {
  id: 'PageDetail',
  title: 'PageDetail',
  category: 'Pages',
  icon: IconItem,
  version: '1.0.0',
  description: `page detail module`,
  windowOptions: {
    width: 680,
    height: 730
  }
};
