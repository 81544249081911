import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'DealerAddresses',
  title: 'DealerAddresses',
  category: '',
  exposes: [
    // {
    //   title: 'dealer-address',
    //   action: { module: 'DealerAddresses', props: { /* */ } },
    // },
    // {
    //   title: 'New dealer-address',
    //   action: { module: 'DealerAddressesDetails', props: { context: 'add' } },
    // },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `module for dealer address`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 830,
    height: 480
  }
};
