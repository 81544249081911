import { meta as News } from 'modules/news/__meta__';
import { meta as NewsDetails } from 'modules/news/details/__meta__';
import { meta as NewsCategories } from 'modules/newscategories/__meta__';
import { meta as NewsCategoriesDetails } from 'modules/newscategories/details/__meta__';
import { meta as Taxonomies } from 'modules/taxonomies/__meta__';
import { meta as TaxonomiesDetails } from 'modules/taxonomies/details/__meta__';
import { meta as Groups } from 'modules/groups/__meta__';
import { meta as GroupsDetails } from 'modules/groups/details/__meta__';
import { meta as Roles } from 'modules/roles/__meta__';
import { meta as RolesDetails } from 'modules/roles/details/__meta__';
import { meta as Users } from 'modules/users/__meta__';
import { meta as UsersDetails } from 'modules/users/details/__meta__';
import { meta as DealerAddresses } from 'modules/dealer-addresses/__meta__';
import { meta as DealerAddressesDetails } from 'modules/dealer-addresses/details/__meta__';
import { meta as about } from 'modules/about/meta';
import { meta as Accessories } from 'modules/accessories/__meta__';
import { meta as AccessoriesDetails } from 'modules/accessories/details/__meta__';
import { meta as AccessoryCategories } from 'modules/accessory-categories/__meta__';
import { meta as AccessoryCategoriesDetails } from 'modules/accessory-categories/details/__meta__';
import { meta as AddressPicker } from 'modules/address-picker/meta';
import { meta as BlockManagement } from 'modules/block-management/meta';
import { meta as Calculator } from 'modules/calculator/meta';
import { meta as Calendar } from 'modules/calendar/meta';
import { meta as ColorDetails } from 'modules/colors/details/__meta__';
import { meta as Contents } from 'modules/contents/__meta__';
import { meta as Contests } from 'modules/contests/__meta__';
import { meta as ContentsDetails } from 'modules/contents/details/__meta__';
import { meta as ContestCategories } from 'modules/contest-categories/__meta__';
import { meta as ContestCategoryDetails } from 'modules/contest-categories/details/__meta__';
import { meta as ContestsDetails } from 'modules/contests/details/__meta__';
import { meta as ContestSubscriptions } from 'modules/contestsubscriptions/__meta__';
import { meta as ContestSubscriptionsDetails } from 'modules/contestsubscriptions/details/__meta__';
import { meta as Dealers } from 'modules/dealers/__meta__';
import { meta as DealersDetails } from 'modules/dealers/details/__meta__';
import { meta as DMS } from 'modules/dms/__meta__';
import { meta as DMSDetails } from 'modules/dms/details/__meta__';
import { meta as DynamicModule } from 'modules/dynamic-module/meta';
import { meta as Events } from 'modules/events/__meta__';
import { meta as EventsDetails } from 'modules/events/details/__meta__';
import { meta as EventEditions } from 'modules/event-editions/__meta__';
import { meta as EventEditionDetails } from 'modules/event-editions/details/__meta__';
import { meta as EventLocals } from 'modules/event-locals/__meta__';
import { meta as EventLocalDetails } from 'modules/event-locals/details/__meta__';
import { meta as FilePicker } from 'modules/file-picker/meta';
import { meta as Help } from 'modules/help/meta';
import { meta as ManageColors } from 'modules/colors/__meta__';
import { meta as ManagePage } from 'modules/manage-page/meta';
import { meta as ManualCategories } from 'modules/manual-categories/__meta__';
import { meta as ManualCategoriesDetails } from 'modules/manual-categories/details/__meta__';
import { meta as Manuals } from 'modules/manuals/__meta__';
import { meta as ManualsDetails } from 'modules/manuals/details/__meta__';
import { meta as Media } from 'modules/media/__meta__';
import { meta as MediaDetails } from 'modules/media/details/__meta__';
import { meta as MenuItems } from 'modules/menu-items/__meta__';
import { meta as MenuItemsDetails } from 'modules/menu-items/details/__meta__';
import { meta as Menus } from 'modules/menus/__meta__';
import { meta as MenusDetails } from 'modules/menus/details/__meta__';
import { meta as Offers } from 'modules/offers/__meta__';
import { meta as OfferCampaigns } from 'modules/offercampaigns/__meta__';
import { meta as OfferCampaignsDetails } from 'modules/offercampaigns/details/__meta__';
import { meta as OfferCategories } from 'modules/offercategories/__meta__';
import { meta as OfferCategoriesDetails } from 'modules/offercategories/details/__meta__';
import { meta as OffersDetails } from 'modules/offers/details/__meta__';
import { meta as PageDetail } from 'modules/manage-page/details/meta';
import { meta as PriceUpload } from 'modules/price-upload/meta';
import { meta as QuickNote } from 'modules/quick-note/meta';
import { meta as Recalls } from 'modules/recalls/__meta__';
import { meta as RecallsDetails } from 'modules/recalls/details/__meta__';
import { meta as RentalServices } from 'modules/rental-services/__meta__';
import { meta as RentalServicesDetails } from 'modules/rental-services/details/__meta__';
import { meta as Sample } from 'modules/__sample/meta';
import { meta as Sections } from 'modules/sections/__meta__';
import { meta as SectionsDetails } from 'modules/sections/details/__meta__';
import { meta as ServiceCategories } from 'modules/service-categories/__meta__';
import { meta as ServiceCategoriesDetails } from 'modules/service-categories/details/__meta__';
import { meta as ServiceItemCategories } from 'modules/service-item-categories/__meta__';
import { meta as ServiceItemCategoriesDetails } from 'modules/service-item-categories/details/__meta__';
import { meta as ServiceItems } from 'modules/service-items/__meta__';
import { meta as ServiceItemsDetails } from 'modules/service-items/details/__meta__';
import { meta as Services } from 'modules/services/__meta__';
import { meta as ServicesDetails } from 'modules/services/details/__meta__';
import { meta as settings } from 'modules/settings/meta';
import { meta as Specs } from 'modules/specs/__meta__';
import { meta as SpecsDetails } from 'modules/specs/details/__meta__';
import { meta as Template } from 'modules/template/meta';
import { meta as TestDrives } from 'modules/test-drives/__meta__';
import { meta as TestDriveDetails } from 'modules/test-drives/details/__meta__';
import { meta as VehicleCategories } from 'modules/vehiclecategories/__meta__';
import { meta as VehicleCategoriesDetails } from 'modules/vehiclecategories/details/__meta__';
import { meta as VehicleModelYears } from 'modules/vehiclemodelyears/__meta__';
import { meta as VehicleModelYearsDetails } from 'modules/vehiclemodelyears/details/__meta__';
import { meta as Vehicles } from 'modules/vehicles/__meta__';
import { meta as VehiclesDetails } from 'modules/vehicles/details/__meta__';
import { meta as VehicleVersions } from 'modules/vehicleversions/__meta__';
import { meta as VehicleVersionsDetails } from 'modules/vehicleversions/details/__meta__';
import { meta as WindowManager } from 'modules/window-manager/meta';

const modules = {
  available: {
    // !IMPORTANT! Dynamic content ahead
    News                : { fileName: `news`, ...News},
    NewsDetails         : { fileName: `news/details`, ...NewsDetails},
    NewsCategories      : { fileName: `newscategories`, ...NewsCategories},
    NewsCategoriesDetails: { fileName: `newscategories/details`, ...NewsCategoriesDetails},
    Taxonomies          : { fileName: `taxonomies`, ...Taxonomies},
    TaxonomiesDetails   : { fileName: `taxonomies/details`, ...TaxonomiesDetails},
    Groups              : { fileName: `groups`, ...Groups},
    GroupsDetails       : { fileName: `groups/details`, ...GroupsDetails},
    Roles               : { fileName: `roles`, ...Roles},
    RolesDetails        : { fileName: `roles/details`, ...RolesDetails},
    Users               : { fileName: `users`, ...Users},
    UsersDetails        : { fileName: `users/details`, ...UsersDetails},
    DealerAddresses    : { fileName: `dealer-addresses`, ...DealerAddresses},
    DealerAddressesDetails: { fileName: `dealer-addresses/details`, ...DealerAddressesDetails},
    About                        : { fileName: `about`, ...about},
    Accessories                  : { fileName: `accessories`, ...Accessories},
    AccessoriesDetails           : { fileName: `accessories/details`, ...AccessoriesDetails},
    AccessoryCategories          : { fileName: `accessory-categories`, ...AccessoryCategories},
    AccessoryCategoriesDetails   : { fileName: `accessory-categories/details`, ...AccessoryCategoriesDetails},
    AddressPicker                : { fileName: `address-picker`, ...AddressPicker},
    BlockManagement              : { fileName: `block-management`, ...BlockManagement},
    Calculator                   : { fileName: `calculator`, ...Calculator},
    Calendar                     : { fileName: `calendar`, ...Calendar},
    Color                        : { fileName: `colors/details`, ...ColorDetails},
    ColorDetails                 : { fileName: `colors/details`, ...ColorDetails},
    Contents                     : { fileName: `contents`, ...Contents},
    ContentsDetails              : { fileName: `contents/details`, ...ContentsDetails},
    Contests                     : { fileName: `contests`, ...Contests},
    ContestsDetails              : { fileName: `contests/details`, ...ContestsDetails},
    ContestCategories            : { fileName: `contest-categories`, ...ContestCategories},
    ContestCategoryDetails       : { fileName: `contest-categories/details`, ...ContestCategoryDetails},
    ContestSubscriptions: { fileName: `contestsubscriptions`, ...ContestSubscriptions},
    ContestSubscriptionsDetails: { fileName: `contestsubscriptions/details`, ...ContestSubscriptionsDetails},
    Dealers                      : { fileName: `dealers`, ...Dealers},
    DealersDetails               : { fileName: `dealers/details`, ...DealersDetails},
    DMS                          : { fileName: `dms`, ...DMS},
    DMSDetails                   : { fileName: `dms/details`, ...DMSDetails},
    DynamicModule                : { fileName: `dynamic-module`, ...DynamicModule},
    Events                       : { fileName: `events`, ...Events},
    EventsDetails                : { fileName: `events/details`, ...EventsDetails},
    EventEditions                : { fileName: `event-editions`, ...EventEditions},
    EventEditionDetails          : { fileName: `event-editions/details`, ...EventEditionDetails},
    EventLocals                  : { fileName: `event-locals`, ...EventLocals},
    EventLocalDetails            : { fileName: `event-locals/details`, ...EventLocalDetails},
    FilePicker                   : { fileName: `file-picker`, ...FilePicker},
    Help                         : { fileName: `help`, ...Help},
    ManageColors                 : { fileName: `colors`, ...ManageColors},
    ManagePage                   : { fileName: `manage-page`, ...ManagePage},
    ManualCategories             : { fileName: `manual-categories`, ...ManualCategories},
    ManualCategoriesDetails      : { fileName: `manual-categories/details`, ...ManualCategoriesDetails},
    Manuals                      : { fileName: `manuals`, ...Manuals},
    ManualsDetails               : { fileName: `manuals/details`, ...ManualsDetails},
    Media                        : { fileName: `media`, ...Media},
    MediaDetails                 : { fileName: `media/details`, ...MediaDetails},
    MenuItems                    : { fileName: `menu-items`, ...MenuItems},
    MenuItemsDetails             : { fileName: `menu-items/details`, ...MenuItemsDetails},
    Menus                        : { fileName: `menus`, ...Menus},
    MenusDetails                 : { fileName: `menus/details`, ...MenusDetails},
    OfferCampaigns               : { fileName: `offercampaigns`, ...OfferCampaigns},
    OfferCampaignsDetails        : { fileName: `offercampaigns/details`, ...OfferCampaignsDetails},
    OfferCategories              : { fileName: `offercategories`, ...OfferCategories},
    OfferCategoriesDetails       : { fileName: `offercategories/details`, ...OfferCategoriesDetails},
    Offers                       : { fileName: `offers`, ...Offers},
    OffersDetails                : { fileName: `offers/details`, ...OffersDetails},
    PageDetail                   : { fileName: `manage-page/details`, ...PageDetail},
    PriceUpload                  : { fileName: `price-upload`, ...PriceUpload},
    QuickNote                    : { fileName: `quick-note`, ...QuickNote},
    Recalls                      : { fileName: `recalls`, ...Recalls},
    RecallsDetails               : { fileName: `recalls/details`, ...RecallsDetails},
    RentalServices               : { fileName: `rental-services`, ...RentalServices},
    RentalServicesDetails        : { fileName: `rental-services/details`, ...RentalServicesDetails},
    Sample                       : { fileName: `__sample`, ...Sample},
    Sections                     : { fileName: `sections`, ...Sections},
    SectionsDetails              : { fileName: `sections/details`, ...SectionsDetails},
    ServiceCategories            : { fileName: `service-categories`, ...ServiceCategories},
    ServiceCategoriesDetails     : { fileName: `service-categories/details`, ...ServiceCategoriesDetails},
    ServiceItemCategories        : { fileName: `service-item-categories`, ...ServiceItemCategories},
    ServiceItemCategoriesDetails : { fileName: `service-item-categories/details`, ...ServiceItemCategoriesDetails},
    ServiceItems                 : { fileName: `service-items`, ...ServiceItems},
    ServiceItemsDetails          : { fileName: `service-items/details`, ...ServiceItemsDetails},
    Services                     : { fileName: `services`, ...Services},
    ServicesDetails              : { fileName: `services/details`, ...ServicesDetails },
    Settings                     : { fileName: `settings`, ...settings},
    Specs                        : { fileName: `specs`, ...Specs},
    SpecsDetails                 : { fileName: `specs/details`, ...SpecsDetails},
    Template                     : { fileName: `template`, ...Template},
    TestDrives                   : { fileName: `test-drives`, ...TestDrives},
    TestDriveDetails             : { fileName: `test-drives/details`, ...TestDriveDetails},
    VehicleCategories            : { fileName: `vehiclecategories`, ...VehicleCategories},
    VehicleCategoriesDetails     : { fileName: `vehiclecategories/details`, ...VehicleCategoriesDetails},
    VehicleModelYears            : { fileName: `vehiclemodelyears`, ...VehicleModelYears},
    VehicleModelYearsDetails     : { fileName: `vehiclemodelyears/details`, ...VehicleModelYearsDetails},
    Vehicles                     : { fileName: `vehicles`, ...Vehicles},
    VehiclesDetails              : { fileName: `vehicles/details`, ...VehiclesDetails},
    VehicleVersions              : { fileName: `vehicleversions`, ...VehicleVersions},
    VehicleVersionsDetails       : { fileName: `vehicleversions/details`, ...VehicleVersionsDetails},
    WindowManager                : { fileName: `window-manager`, ...WindowManager},
  }
};

export default modules;
