import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'EventsDetails',
  title: 'EventsDetails',
  category: 'Event',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `module to register the events`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 700,
    height: 500
  }
};
