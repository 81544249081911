import {
  CarVersion as Icon
} from 'components/icons';

export const meta = {
  id: 'VehicleVersions',
  title: 'VehicleVersions',
  category: 'Vehicles',
  // exposes: [
  //   {
  //     title: 'VehicleVersion',
  //     action: { module: 'VehicleVersions', props: { /* */ } },
  //   },
  //   {
  //     title: 'New VehicleVersion',
  //     action: { module: 'VehicleVersionsDetails', props: { context: 'add' } },
  //   },
  // ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `Manage Versions of a Vehicle (model)`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 735,
    height: 670
  }
};
