import * as React from 'react';

const SvgStoreOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="StoreOutlined_svg__MuiSvgIcon-root StoreOutlined_svg__MuiSvgIcon-fontSizeMedium StoreOutlined_svg__MuiSvgIcon-fontSizeLarge StoreOutlined_svg__css-1shn170"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="StoreOutlinedIcon"
    tabIndex={-1}
    data-icon="1"
    {...props}
  >
    <path
      d="m18.36 9 .6 3H5.04l.6-3h12.72M20 4H4v2h16V4zm0 3H4l-1 5v2h1v6h10v-6h4v6h2v-6h1v-2l-1-5zM6 18v-4h6v4H6z"
      fill="#FFF"
    />
  </svg>
);

export default SvgStoreOutlined;
