import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';
import { deleteCookie } from 'utils/cookies';
import { fetchGQL, storeTokens } from 'utils/graphql';

const AuthContext = createContext();

function AuthProvider ({ children }) {
  const [providerData, setProviderData] = useState({
    // ready: false,
    data: {
      isAuthenticated: false,
      loading: true,
      sub: null,
      name: '',
      nickname: '',
      picture: '',
      email: ''
    }
  });

  const value = {
    ...providerData,
    setAuthCode (code) {
      setProviderData({
        ...value,
        code,
      });
    },
    async signOut () {
      return fetchGQL('/api/login/logout')
        .then((logoutRes) => {
          const iframe = document.createElement('iframe');
          const _b = document.body;
          _b.appendChild(iframe);
          iframe.addEventListener('load', _ => {
            setProviderData({
              ...value,
              code: null,
              access_token: null,
              refresh_token: null,
              data: {
                sub: null,
                isAuthenticated: false,
                loading: false
              }
            });
            deleteCookie('access_token');
            deleteCookie('refresh_token');
            sessionStorage.removeItem('tokens');
            sessionStorage.removeItem('auth');
          });
          iframe.src = logoutRes.signOutLink;
        }).catch((err) => {
          alert(`Error: Failed to retrieve the correct logout url. Please clear cookies and sessions for better security`);
        });

      // setProviderData({
      //   ...value,
      //   data: {
      //     sub: null // will trigger the useEffect that clears everything up
      //   }
      // });
    },
    setData (key, value) {
      setProviderData({
        ...value,
        data: {
          ...value.data,
          [key]: value
        }
      });
    }
  };

  function fetchProviderData () {
    const currentTokens = sessionStorage.getItem('tokens');

    if (!currentTokens) {
      return;
    }

    setTimeout(async _ => {
      const loginCheck = await fetchGQL('/api/login/check');

      if (!loginCheck?.checkToken) {
        // could NOT revalidate the current user's tokens
        return value.signOut();
      }

      setProviderData({
        ...providerData,
        code: null,
        ...JSON.parse(currentTokens)
      });
    }, 500);
  }

  useEffect(
    () => {
      if (value.code) {
        // has a code...should go for the tokens
        getUserTokensByCode(value.code).then((tokens) => {
          setProviderData({
            ...providerData,
            code: null,
            access_token: tokens.access_token,
            refresh_token: tokens.refresh_token
          });
        });
        // let's update the state to trigger the next useeffect
      }
    },
    [value.code]
  );

  useEffect(
    () => {
      if (value.access_token) {
        // has the access_token...should create cookies, sessionStorage and go for the info
        const tokens = {
          access_token: value.access_token,
          refresh_token: value.refresh_token
        };

        if (!sessionStorage.getItem('tokens')) {
          storeTokens(tokens);
        }

        getUserInfo().then((result) => {
          getUserSettings().then((userSettings) => {
            const data = {
              ...value.data,
              ...result.data.authUserInfo,
              uid: userSettings?.user?.id
            };

            if (result?.data?.authUserInfo) {
              setProviderData({
                ...value,
                data
              });
            }
          });
        });
      }
    },
    [value.access_token]
  );

  useEffect(() => { fetchProviderData(); }, []);

  function getUserInfo () {
    return fetchGQL('/api/login/userinfo')
      .then(async (result) => {
        return result;
      }).catch((err) => {
        return err;
      });
  }

  function getUserSettings () {
    return fetch('/api/login/user-settings')
      .then(async (result) => {
        const json = await result.json();
        let tmpUserSettings = json.data.getUserSettings;
        tmpUserSettings = Array.isArray(tmpUserSettings)
          ? tmpUserSettings[0]
          : tmpUserSettings;
        return tmpUserSettings;
      }).catch((err) => {
        return err;
      });
  }

  async function getUserTokensByCode (code) {
    const tokens = await (await fetch(
      '/api/login/tokens',
      {
        method: 'post',
        headers: {
          'Accept': 'application/json', //eslint-disable-line
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
        body: JSON.stringify({ code }),
      }
    )).json();

    return tokens;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

// this allows you to use it as a simpler hook
function useAuth () {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export {
  AuthProvider,
  AuthContext,
  useAuth
};
