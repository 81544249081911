import SettingsIcon from 'components/icons/Settings';

export const meta = {
  id: 'Settings',
  title: 'Settings',
  category: 'Settings',
  // group: 'General',
  exposes: [
    {
      title: 'System settings',
      action: { module: 'Settings', props: {} },
      desription: 'general-settings-desc',
    },
    {
      title: 'Profile',
      action: { module: 'Settings', props: { context: 'Profile' } },
      desription: 'profile-settings-desc',
    },
    {
      title: 'Customization',
      action: { module: 'Settings', props: { context: 'Customizations' } },
      desription: 'settings-customizations-desc',
    },
  ],
  unique: false,
  icon: SettingsIcon,
  version: '1.0.0',
  description: "settings-description",
  windowOptions: {
    //
  }
};
