import * as React from 'react';

const SvgApplications = (props) => (
  <svg
    className="applications_svg__MuiSvgIcon-root applications_svg__MuiSvgIcon-fontSizeMedium applications_svg__MuiBox-root applications_svg__css-uqopch"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="AppsOutlinedIcon"
    data-icon="1"
    {...props}>
    <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
  </svg>
);

export default SvgApplications;
