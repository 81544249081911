import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'Services',
  title: 'Services',
  category: 'Services',
  exposes: [
    {
      title: 'service',
      action: { module: 'Services', props: { /* */ } },
    },
    {
      title: 'New service',
      action: { module: 'ServicesDetails', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `crud for service`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
