import * as React from 'react';

const SvgNote = (props) => (
  <svg
    className="note_svg__MuiSvgIcon-root note_svg__MuiSvgIcon-fontSizeMedium note_svg__MuiBox-root note_svg__css-uqopch"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="StickyNote2OutlinedIcon"
    data-icon="1"
    {...props}
  >
    <path d="M19 5v9h-5v5H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h10l6-6V5c0-1.1-.9-2-2-2zm-7 11H7v-2h5v2zm5-4H7V8h10v2z" />
  </svg>
);

export default SvgNote;
