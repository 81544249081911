import {
  StoreOutlined as Icon
} from 'components/icons';

export const meta = {
  id: 'DealersDetails',
  title: 'DealersDetails',
  category: 'Dealers',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `CRUD for dealers`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 640,
    height: 610
  }
};
