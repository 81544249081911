import * as React from 'react';

const SvgCollectionsOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="CollectionsOutlined_svg__MuiSvgIcon-root CollectionsOutlined_svg__MuiSvgIcon-fontSizeMedium CollectionsOutlined_svg__MuiSvgIcon-fontSizeLarge CollectionsOutlined_svg__css-1shn170"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CollectionsOutlinedIcon"
    tabIndex={-1}
    data-icon="1"
    {...props}
  >
    <path
      d="M20 4v12H8V4h12m0-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 9.67 1.69 2.26 2.48-3.1L19 15H9zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"
      fill="#FFF"
    />
  </svg>
);

export default SvgCollectionsOutlined;
