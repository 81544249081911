import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'Contests',
  title: 'Contests',
  category: 'Contest',
  exposes: [
    {
      title: 'contest',
      action: { module: 'Contests', props: { /* */ } },
    },
    {
      title: 'New contest',
      action: { module: 'ContestsDetails', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `module to register the contests`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 1060,
    height: 480
  }
};
