import * as React from 'react';

const SvgPrices = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 269 511.61"
    data-icon="1"
    {...props}
  >
    <path
      fillRule="nonzero"
      d="m245.63 215.65-116.28-96.54-39.32 36.45c9.1 4.86 19.4 9.35 31 13.39 3.87-2.31 8.22-3.52 13.05-3.6 7.38-.1 13.71 2.37 18.93 7.43 5.27 5.09 7.98 11.3 8.16 18.59.21 7.33-2.22 13.77-7.31 19.32-5.05 5.22-11.3 7.89-18.69 8.05-7.41.07-13.75-2.4-18.97-7.46-5.36-4.91-8.14-11.12-8.37-18.59-.03-1.32.02-2.6.15-3.86-13.44-4.99-25.31-10.64-35.75-16.76l-46.71 43.29-2.47 258.63c-.06 6.77 4.91 12.57 11.87 12.57l198.45 1.99c6.97.11 12.51-4.99 12.58-12.02l-.32-260.88zm-121.37 44.76h25.73l-.62 16.19c10.81.67 20.26 1.76 28.37 3.25l-5.88 26.85h-30.37c-4.68 0-7.89.76-9.42 2.23-1.53 1.49-2.4 8.84-2.52 13.03l12.74 1.42c15.54 1.76 26.23 5.74 32.09 11.95 5.88 6.21 8.81 14.24 8.81 24.09 0 9.87-1.02 17.72-3.04 23.6-2.02 5.87-4.92 10.35-8.7 13.45-6.88 5.27-15.93 8.31-27.14 9.13l-.59 20.44H117.6l.79-20.44c-12.82-.96-23.68-2.58-32.58-4.86l5.86-27.69c11.21 2.98 22.95 4.47 35.24 4.47 5.12 0 9.91-.27 14.36-.82v-13.02l-12.54-1.42c-16.21-1.61-27-6.35-32.41-14.17-4.72-6.88-7.08-15.86-7.08-26.93 0-14.6 3.01-25.2 9.01-31.8 6.01-6.61 14.49-10.66 25.42-12.15l.59-16.8zm77.49-111.14c-29.27-76.35-65.08-112.23-96.77-122.82-21.86-7.31-41.65-1.93-54.97 10.36C36.59 49.2 29.51 68.7 33.2 89.37c3.25 17.98 14.54 36.92 36.6 53.44l51.24-47.5c4.2-3.86 10.74-4.13 15.24-.4l65.47 54.36zm-149.13 9.45c-25.11-19.9-38.19-43.04-42.15-65.28-5.11-28.81 4.92-56.12 23.9-73.64C53.51 2.14 81.59-5.69 112.3 4.58c42.25 14.12 89.89 63.82 123.53 172.89l-.08.03 28.43 23.6c2.74 2.12 4.5 5.43 4.5 9.15l.32 266.4c-.24 19.89-15.99 35.28-35.9 34.96l-198.23-1.99c-19.96 0-34.99-16.33-34.87-35.81l2.51-262.99c-.17-3.28 1.06-6.62 3.66-9.03l46.45-43.07z"
    />
  </svg>
);

export default SvgPrices;
