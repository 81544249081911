import * as React from 'react';

const SvgCarVersion = (props) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 90.1"
    data-icon="1"
    {...props}
  >
    <path
      d="M10.17 40.11C-.81 34.53.45 28.31 11.48 29L14 33.59l5-15.83c2-6.23 5.37-11.88 11.9-11.88h1.45V4.12a4.12 4.12 0 0 1 8.24 0v1.76h41.7V4.12a4.12 4.12 0 0 1 8.24 0v1.76H96c6.53 0 10.29 5.54 11.87 11.87l3.82 15.35 2.2-4.14c11.34-.66 12.35 5.93.35 11.62l2 3c7.89 8.11 7.15 16.21 5.92 36.24v6.58a3.72 3.72 0 0 1-3.71 3.71h-15.88a3.72 3.72 0 0 1-3.71-3.71V82H47.57a6.08 6.08 0 0 1-1.76 3.33 6.35 6.35 0 0 1-4.43 1.8h-5.27a6.31 6.31 0 0 1-4.43-1.8A6.1 6.1 0 0 1 29.91 82H24v4.35a3.72 3.72 0 0 1-3.71 3.71H4.5a3.72 3.72 0 0 1-3.71-3.67v-8.55a5.46 5.46 0 0 1 0-.58C-.37 61.89-2.06 48 10.17 40.11Zm25.94 37.27h5.27a3.65 3.65 0 1 1 0 7.3h-5.27a3.65 3.65 0 1 1 0-7.3Zm-21-30.32a3.45 3.45 0 0 1 3.44 3.43V58a3.45 3.45 0 0 1-3.44 3.43A3.44 3.44 0 0 1 11.64 58v-7.51a3.44 3.44 0 0 1 3.43-3.43Zm27 .79h38.72a1.89 1.89 0 0 1 1.89 1.89v9a1.89 1.89 0 0 1-1.89 1.89H42.05a1.89 1.89 0 0 1-1.89-1.89v-9a1.89 1.89 0 0 1 1.89-1.89Zm65.72 22.07s7.79 9.67 8.54 11.14h-8.55v-7.38h-92.7v7.38H6.57l8.55-11.13Zm0-22.86a3.44 3.44 0 0 1 3.43 3.43V58a3.44 3.44 0 0 1-3.43 3.43 3.45 3.45 0 0 1-3.46-3.43v-7.51a3.45 3.45 0 0 1 3.44-3.43Zm-88.58-10h86.82l-3.83-15.92c-1-4.85-4.07-9-9-9H33.06c-5 0-7.52 4.31-9 9.05l-4.83 15.89Z"
      style={{
        fillRule: 'evenodd'
      }}
    />
  </svg>
);

export default SvgCarVersion;
