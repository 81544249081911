import * as React from 'react';

const SvgNotification = (props) => (
  <svg
    className="notification_svg__MuiSvgIcon-root notification_svg__MuiSvgIcon-fontSizeMedium notification_svg__MuiBox-root notification_svg__css-uqopch"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="NotificationsActiveOutlinedIcon"
    data-icon="1"
    {...props}>
    <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6zM7.58 4.08 6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2a8.445 8.445 0 0 1 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43a8.495 8.495 0 0 1 3.54 6.42z" />
  </svg>
);

export default SvgNotification;
