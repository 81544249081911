import {
  ManageWindows as Icon
} from 'components/icons';

export const meta = {
  id: 'WindowManager',
  title: 'WindowManager',
  category: 'Settings',
  exposes: [
    {
      title: 'General',
      action: { module: 'WindowManager', props: { /* */ } },
      desription: 'general-desc',
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `Manage the open windows.`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 630,
    height: 370
  }
};
