import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';

const ContextMenuContext = createContext();

function ContextMenuProvider ({ children }) {
  // whatever you set here as default, will be used in both server side and client as well
  const [providerData, setProviderData] = useState({
    ready: false,
    data: {
      // add default data to your provider here
    }
  });

  // you can get extra data asynchronously if needed
  // this will only affect the client side
  // function fetchProviderData () {
  //   //! TODO: Add your code here to fetch or mount data to fill the provider with. This will be executed only in serveer side
  //   setProviderData({
  //     ...providerData,
  //     ready: true,
  //   });
  // }

  useEffect(() => {
    window.contextMenu = { items: {} };
  }, []);

  const value = {
    ...providerData,
    setData (key, value) {
      setProviderData({
        ...providerData,
        data: {
          ...providerData.data,
          [key]: value
        }
      });
    },
    setContextMenu (data) {
      setProviderData({
        ...providerData,
        data: {
          open: !!data,
          items: window.contextMenu.items,
          ...(data || {})
        }
      });
    },
    // items: window.contextMenu,
    addItems (id, items) {
      window.contextMenu.items[id] = items;
    },
    setItems (items) {
      window.contextMenu.items = {0: items};
    },
    clearItems (id) {
      if (id) {
        delete window.contextMenu.items[id];
      } else {
        window.contextMenu.items = {};
      }
    }
  };

  return (
    <ContextMenuContext.Provider value={value} >
      {children}
    </ContextMenuContext.Provider>
  );
}

// this allows you to use it as a simpler hook
function useContextMenu () {
  const context = useContext(ContextMenuContext);
  if (context === undefined) {
    throw new Error('useContextMenu must be used within a ContextMenuProvider');
  }

  return context;
}

export {
  ContextMenuProvider,
  ContextMenuContext,
  useContextMenu
};
