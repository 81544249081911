import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';
import { doQuery } from 'utils/graphql/';
import queries from './queries';

const ProductContext = createContext();

function ProductProvider ({ children }) {
  // whatever you set here as default, will be used in both server side and client as well
  const [providerData, setProviderData] = useState({
    ready: false,
    data: []
  });

  // you can get extra data asynchronously if needed
  // this will only affect the client side
  async function fetchProviderData () {
    try {
      const result  = await doQuery(queries.findAll);

      if (!result?.data?.products) {
        console.warn(result);
        return;
      }

      setProviderData(() => ({
        ...providerData,
        data : result.data.products,
        ready: true
      }));
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  useEffect(() => { fetchProviderData(); }, []);

  const value = {
    fetchData: fetchProviderData,
    ...providerData,
    setData (key, value) {
      setProviderData({
        ...providerData,
        data: {
          ...providerData.data,
          [key]: value
        }
      });
    }
    // TODO: add more methods/actions here
  };

  return (
    <ProductContext.Provider value={value}>

      {children}

      {/*
        //! you can add extra components here
       */}
    </ProductContext.Provider>
  );
}

// this allows you to use it as a simpler hook
function useProduct () {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useProduct must be used within a ProductProvider');
  }

  return context;
}

export {
  ProductProvider,
  ProductContext,
  useProduct
};
