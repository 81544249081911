import { gql } from 'utils/graphql/';

const findAll = gql`
  query {
    products {
      id
      name
    }
  }
`;

export default { findAll };