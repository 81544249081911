import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'Template',
  title: 'Template',
  category: 'Pages',
  // exposes: [
  //   {
  //     title: 'General',
  //     action: { module: 'Template', props: { /* */ } },
  //     desription: 'general-desc',
  //   },
  // ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `A template used for multiple pages to deal with page settings, other than just dealing with blocks and contents.`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 830,
    height: 780
  }
};
