import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'MenuItems',
  title: 'Items for menu',
  category: 'Pages',
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `items for menu`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
