import {
  ColorLensOutlined as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'ManageColors',
  title: 'ManageColors',
  category: 'Vehicles',
  exposes: [
    {
      title: 'Colors',
      action: { module: 'ManageColors', props: { /* */ } },
      desription: 'general-desc',
    },
    {
      title: 'New Color',
      action: { module: 'ColorDetails', props: { context: 'add' } },
      desription: 'general-desc',
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `TODO: add a description here!`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 730,
    height: 470
  }
};
