import * as React from 'react';

const SvgCollectionsBookmarkOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="CollectionsBookmarkOutlined_svg__MuiSvgIcon-root CollectionsBookmarkOutlined_svg__MuiSvgIcon-fontSizeMedium CollectionsBookmarkOutlined_svg__MuiSvgIcon-fontSizeLarge CollectionsBookmarkOutlined_svg__css-1shn170"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CollectionsBookmarkOutlinedIcon"
    tabIndex={-1}
    data-icon="1"
    {...props}
  >
    <path
      d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-3 2v5l-1-.75L15 9V4h2zm3 12H8V4h5v9l3-2.25L19 13V4h1v12z"
      fill="#FFF"
    />
  </svg>
);

export default SvgCollectionsBookmarkOutlined;
