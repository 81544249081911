import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'OfferCategories',
  title: 'OfferCategories',
  category: 'Offers',
  exposes: [
    {
      title: 'offerCategory',
      action: { module: 'OfferCategories', props: { /* */ } },
    },
    {
      title: 'New offerCategory',
      action: { module: 'OfferCategoriesDetails', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `categories for offer`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
