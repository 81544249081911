import * as React from 'react';

const SvgInfo = (props) => (
  <svg
    className="info_svg__MuiSvgIcon-root info_svg__MuiSvgIcon-fontSizeMedium info_svg__MuiBox-root info_svg__css-uqopch"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="InfoOutlinedIcon"
    data-icon="1"
    {...props}>
    <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </svg>
);

export default SvgInfo;
