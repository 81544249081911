import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'NewsCategoriesDetails',
  title: 'NewsCategoriesDetails',
  category: 'News',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `Module to register the news category`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 440,
    height: 410
  }
};
