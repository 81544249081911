import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';
import { toast } from 'react-toastify';

const UiContext = createContext();

function UiProvider ({ children }) {
  // whatever you set here as default, will be used in both server side and client as well
  const [providerData, setProviderData] = useState({
    ready: false,
    data: {
      notificationBar: { open: false, list: {} },
      mainMenu: { open: false }
      // add default data to your provider here
    }
  });

  // you can get extra data asynchronously if needed
  // this will only affect the client side
  function fetchProviderData () {
    //! TODO: Add your code here to fetch or mount data to fill the provider with. This will be executed only in serveer side
    setProviderData({
      ...providerData,
      ready: true,
    });
  }

  useEffect(() => { fetchProviderData(); }, []);

  const value = {
    ...providerData,
    setData (key, value) {
      setProviderData({
        ...providerData,
        data: {
          ...providerData.data,
          [key]: value
        }
      });
    }
    // TODO: add more methods/actions here
  };

  value.toggleNotifcationBar = function toggleNotifcationBar (force) {
    setProviderData(currentData => {
      const newData = { ...currentData };
      if (force === true || force === false) {
        newData.data.notificationBar.open = force;
      } else {
        newData.data.notificationBar.open = !newData.data.notificationBar.open;
      }
      return newData;
    });
  };

  value.toggleMainMenu = function toggleMainMenu (force, options = {}) {
    setProviderData(currentData => {
      const newData = { ...currentData };
      if (force === true || force === false) {
        newData.data.mainMenu.open = force;
      } else {
        newData.data.mainMenu.open = !newData.data.mainMenu.open;
      }
      newData.data.mainMenu.options = options;
      return newData;
    });
  };

  value.addNotification = function addNotification (data) {
    if (!data.id) {
      data.id = data.title + (new Date()).getTime();
    }

    setProviderData(currentData => {
      const newData = { ...currentData };
      newData.data.notificationBar.list[data.id] = data;
      return newData;
    });
  };

  value.clearNotification = function clearNotification (id) {
    setProviderData(currentData => {
      const newData = { ...currentData };
      delete newData.data.notificationBar.list[id];
      return newData;
    });
  };

  return (
    <UiContext.Provider value={value}>

      {children}

      {/*
        //! you can add extra components here
       */}
    </UiContext.Provider>
  );
}

// this allows you to use it as a simpler hook
function useUi () {
  const context = useContext(UiContext);
  if (context === undefined) {
    throw new Error('useUi must be used within a UiProvider');
  }

  return context;
}

export {
  UiProvider,
  UiContext,
  toast,
  useUi
};
