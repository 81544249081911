import { gql } from 'utils/graphql/';

const findByLocale = gql`
  query ($locale: String!) {
    tenantByLocale(locale: $locale) {
      id
      name
      currency
      locale
    }
  }
`;


export { findByLocale };
