import * as React from 'react';

const SvgSpecs = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 94.59"
    style={{
      enableBackground: 'new 0 0 122.88 94.59'
    }}
    xmlSpace="preserve"
    data-icon="1"
    {...props}
  >
    <path
      d="M43.58 92.2 31.9 80.53h-8.04c-2.81 0-5.11-2.3-5.11-5.11v-8.7h-4.87V76.9c0 2.17-1.78 3.95-3.95 3.95H3.95C1.78 80.85 0 79.07 0 76.9V42.4c0-2.17 1.78-3.95 3.95-3.95h5.98c2.17 0 3.95 1.78 3.95 3.95v10.18h4.87v-9.36c0-2.81 2.3-5.11 5.11-5.11h8.54l12.07-12.83c1.4-1.22 3.26-1.65 5.43-1.56h49.73c1.72.19 3.03.85 3.83 2.09.8 1.22.67 1.91.67 3.28v23.49H109V42.4c0-2.17 1.78-3.95 3.95-3.95h5.98c2.17 0 3.95 1.78 3.95 3.95v34.5c0 2.17-1.78 3.95-3.95 3.95h-5.98c-2.17 0-3.95-1.78-3.95-3.95V66.72h-4.87v.92c0 2.73.08 4.38-1.66 6.64-.33.43-.7.84-1.11 1.22L83.53 92.96c-.89.99-2.24 1.53-4.02 1.63h-30.4c-2.27-.1-4.12-.88-5.53-2.39zm20.13-30.42-12.64-1.19 10.48-22.96h14.33L67.75 50.8l14.62 1.62-26.84 32.22 8.18-22.86zM51.98 0h34.5c2.17 0 3.95 1.78 3.95 3.95v5.98c0 2.17-1.78 3.95-3.95 3.95H76.3v5.03H62.16v-5.03H51.98c-2.17 0-3.95-1.78-3.95-3.95V3.95c0-2.17 1.78-3.95 3.95-3.95z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd'
      }}
    />
  </svg>
);

export default SvgSpecs;
