import {
  CarModel as Icon
} from 'components/icons';

export const meta = {
  id: 'Vehicles',
  title: 'Vehicles',
  category: 'Vehicles',
  exposes: [
    {
      title: 'Vehicle',
      action: { module: 'Vehicles', props: { /* */ } },
    },
    {
      title: 'New Vehicle',
      action: { module: 'VehiclesDetails', props: { context: 'add' } },
    },
    {
      title: 'VehicleCategory',
      action: { module: 'VehicleCategories', props: { /* */ } },
    },
    {
      title: 'New VehicleCategory',
      action: { module: 'VehicleCategoriesDetails', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `Module to manage vehicles`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 870,
    height: 650
  }
};
