import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'ContestSubscriptionsDetails',
  title: 'ContestSubscriptionsDetails',
  category: 'Contests',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `module for subscriptions `,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 640,
    height: 610
  }
};
