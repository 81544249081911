import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'ContestCategories',
  title: 'ContestCategories',
  category: 'Contest',
  exposes: [
    {
      title: 'ContestCategories',
      action: { module: 'ContestCategories', props: { /* */ } },
    },
    {
      title: 'New ContestCategories',
      action: { module: 'ContestCategories', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `module to register the contestCategories`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
