import {
  Prices as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'PriceUpload',
  title: 'PriceUpload',
  category: 'Prices',
  exposes: [
    {
      title: 'General',
      action: { module: 'PriceUpload', props: { /* */ } },
      desription: 'general-desc',
    },
    {
      title: 'BTB',
      action: { module: 'PriceUpload', props: { context: 'btb' } },
      desription: 'general-desc',
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `The module to upload a spreadsheet for prices related to vehicles, accessories and services.`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 890,
    height: 850
  }
};
