import { Info as Icon } from 'components/icons';

export const meta = {
  id: 'Manuals',
  title: 'Manuals',
  category: 'Vehicles',
  exposes: [
    {
      title: 'manuals',
      action: {
        module: 'Manuals',
        props: {
          /* */
        }
      }
    },
    {
      title: 'New manuals',
      action: { module: 'ManualsDetails', props: { context: 'add' } }
    },
    {
      title: 'manualCategory',
      action: {
        module: 'ManualCategories',
        props: {
          /* */
        }
      }
    },
    {
      title: 'New manualCategory',
      action: { module: 'ManualCategoriesDetails', props: { context: 'add' } }
    }
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `Manuals CRUD`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
