import React from 'react';
import { Icon } from 'semantic-ui-react';

const IconItem = () => <Icon name="window restore outline" />;

export const meta = {
  id: 'ManagePage',
  title: 'ManagePage',
  category: 'Pages',
  exposes: [
    {
      title: 'General',
      action: { module: 'ManagePage', props: { /* */ } },
      desription: 'general-desc',
    },
    {
      title: 'Add new',
      action: { module: 'PageDetail', props: { context: 'add' } },
      desription: 'general-desc',
    },
  ],
  unique: true,
  icon: IconItem,
  version: '1.0.0',
  description: `manage page module`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
