import * as React from 'react';

const SvgTodayOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="TodayOutlined_svg__MuiSvgIcon-root TodayOutlined_svg__MuiSvgIcon-fontSizeMedium TodayOutlined_svg__MuiSvgIcon-fontSizeLarge TodayOutlined_svg__css-1shn170"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="TodayOutlinedIcon"
    tabIndex={-1}
    data-icon="1"
    {...props}
  >
    <path
      d="M19 3h-1V1h-2v2H8V1H6v2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V9h14v10zm0-12H5V5h14v2zM7 11h5v5H7z"
      fill="#FFF"
    />
  </svg>
);

export default SvgTodayOutlined;
