import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'Sections',
  title: 'Sections',
  category: 'Pages',
  // exposes: [
  //   {
  //     title: 'Section',
  //     action: { module: 'Sections', props: { /* */ } },
  //   },
  //   {
  //     title: 'New Section',
  //     action: { module: 'SectionsDetails', props: { context: 'add' } },
  //   },
  // ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `A section used in pages`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 735,
    height: 600
  }
};
