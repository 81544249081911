import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'TestDriveDetails',
  title: 'TestDriveDetails',
  category: 'TestDrive',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `module to register the event`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 1000,
    height: 600
  }
};
