import { Info as Icon } from 'components/icons';

export const meta = {
  id: 'TestDrives',
  title: 'TestDrives',
  category: 'Event',
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `module to register the test drive`,
  windowOptions: {
    width: 600,
    height: 600
  }
};
