import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'ServiceItemCategoriesDetails',
  title: 'ServiceItemCategoriesDetails',
  category: 'Services',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `<!-- TODO: add a description here! -->`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 640,
    height: 610
  }
};
