import {
  CarVersion as Icon
} from 'components/icons';

export const meta = {
  id: 'VehicleModelYears',
  title: 'VehicleModelYears',
  category: 'Vehicles',
  // exposes: [
  //   {
  //     title: 'vehiclemodelyears',
  //     action: { module: 'VehicleModelYears', props: { /* */ } },
  //   },
  //   {
  //     title: 'New vehiclemodelyears',
  //     action: { module: 'VehicleModelYearsDetails', props: { context: 'add' } },
  //   },
  // ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `Module to manage model year(ano-modelo) of each version`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
