import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'DMS',
  title: 'DMS',
  category: 'Dealers',
  exposes: [
    {
      title: 'DMS',
      action: { module: 'DMS', props: { /* */ } },
    },
    {
      title: 'New DMS',
      action: { module: 'DMSDetails', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `The DMS used by dealers`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 440,
    height: 460
  }
};
