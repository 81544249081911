import { useState, useEffect } from 'react';
import { doQuery /*doMutation*/ } from 'utils/graphql/';

// const GraphQLContext = createContext();

// function GraphQLProvider (props) {
//   const { children } = props;
//   // whatever you set here as default, will be used in both server side and client as well
//   const [providerData, setProviderData] = useState({
//     ready: false,
//     data: {},
//     doQuery,
//     doMutation
//   });

//   // you can get extra data asynchronously if needed
//   // this will only affect the client side
//   // function fetchProviderData () {
//   //   //! TODO: Add your code here to fetch or mount data to fill the provider with. This will be executed only in serveer side
//   //   setProviderData({
//   //     ...providerData,
//   //     ready: true,
//   //   });
//   // }

//   // useEffect(() => { fetchProviderData(); }, []);

//   const value = {
//     ...providerData,
//     // setData (key, value) {
//     //   setProviderData({
//     //     ...providerData,
//     //     data: {
//     //       ...providerData.data,
//     //       [key]: value
//     //     }
//     //   });
//     // }
//   };

//   return (
//     <GraphQLContext.Provider value={value}>
//       {children}
//     </GraphQLContext.Provider>
//   );
// }

// // this allows you to use it as a simpler hook
// function useGraphQL () {
//   const context = useContext(GraphQLContext);
//   if (context === undefined) {
//     throw new Error(`useGraphQL must be used within a GraphQLProvider`);
//   }

//   return context;
// }

export function useQuery(query, variables) {
  const [state, setState] = useState({
    data: null,
    loading: true,
    error: null
  });

  function run() {
    doQuery(query, variables)
      .then((result) => {
        setState({
          data: result,
          loading: false,
          error: null
        });
      })
      .catch((err) => {
        setState({
          data: null,
          loading: false,
          error: err
        });
      });
  }

  useEffect(() => {
    run();
  }, []);

  if (typeof window === 'undefined') {
    run();
  }

  return state;
}

// export {
//   // GraphQLProvider,
//   // GraphQLContext,
//   // useGraphQL,
//   useQuery
// };
