// inspired from https://github.com/gyzerok/adrenaline/blob/master/src/network/defaultNetworkLayer.js
import getConfig from 'next/config';
const { serverRuntimeConfig } = getConfig();
import { setCookie } from 'utils/cookies';
import { fetch } from 'utils/http';

const DAY = 86400;

export function isClient() {
  return typeof window !== 'undefined';
}

export function doQuery(query, variables, options = {}) {
  const endpoint = isClient() ? '/api/graphql' :
    serverRuntimeConfig.GRAPHQL_ADDR;
  if (Array.isArray(query)) {
    query = query.join('\n');
  }

  const opts = {
    method: 'post',
    headers: {
      Accept: 'application/json', //eslint-disable-line
      'Content-Type': 'application/json',
      'Cache-Control': 'no-store'
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      query,
      variables
    })
  };

  const access_token = options?.access_token;
  if (access_token) {
    opts.headers.Authorization = 'Bearer ' + access_token;
  }

  return fetch(endpoint, opts)
    .then(async (res) => {
      const { data } = res.json ? await res.json() : res;
      if (data.errors) {
        console.error(data.errors);
      }
      return data;
    })
    .catch((error) => {
      return error;
    });
}

let refreshing = null;

export function refreshTokens() {
  refreshing =
    refreshing ||
    new Promise((resolve, reject) => {
      fetch('/api/login/refresh')
        .then(async (result) => {
          const data = await result.json();
          if (data.errors) {
            reject(data.errors);
          }
          resolve({
            access_token: data.access_token,
            refresh_token: data.refresh_token
          });
        })
        .catch(async (err) => {
          console.error(err);
          reject(err);
        });
      refreshing = null;
    });
  return refreshing;
}

export async function storeTokens(tokens) {
  return Promise.all([
    new Promise((resolve, reject) => {
      const curSessVals = JSON.parse(sessionStorage.getItem('tokens'));
      tokens.refresh_token = tokens.refresh_token || curSessVals.refresh_token;
      sessionStorage.setItem('tokens', JSON.stringify(tokens));
      resolve();
    }),
    new Promise((resolve, reject) => {
      setCookie('access_token', tokens.access_token, {
        SameSite: 'Strict',
        'max-age': DAY
      });
      if (tokens.refresh_token) {
        setCookie('refresh_token', tokens.refresh_token, {
          'max-age': DAY * 5,
          path: '/api/login/refresh',
          SameSite: 'Strict'
        });
      }
      resolve();
    })
  ]);
}

export function fetchGQL(endpoint, options, extra) {
  const opts = {
    method: 'post',
    headers: {
      Accept: 'application/json', //eslint-disable-line
      'Content-Type': 'application/json'
      // authorization: CURRENT_TOKEN ? `Bearer ${CURRENT_TOKEN}` : ""
    },
    credentials: 'same-origin',
    ...options
  };

  return fetch(endpoint, opts)
    .then(async (result) => {
      result = await result.json();
      if (result?.errors?.[0].message === 'Unauthorized') {
        let curTokens = sessionStorage?.getItem('tokens');
        curTokens = curTokens && JSON.parse(curTokens);
        if (curTokens) {
          // user has a session, but the current token is not authorized...
          // probably because it's just expired
          const tokens = await refreshTokens();
          if (tokens) {
            await storeTokens(tokens);
            return fetchGQL(endpoint, options, extra);
          }
        }
        // TODO: deal with an invalid token
        console.error('REALLY not authorized!');
      }
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export function doMutation(mutation, variables, files, options = {}) {
  if (Array.isArray(mutation)) {
    mutation = mutation.join('\n');
  }
  const endpoint = '/api/graphql';

  const headers = {
    Accept: 'application/json', // eslint-disable-line
    'Content-Type': 'application/json',
    'Cache-Control': 'no-store'
  };

  const access_token = options?.access_token;
  if (access_token) {
    headers.Authorization = 'Bearer ' + access_token;
  }

  if (!files) {
    return fetch(endpoint, {
      method: 'post',
      headers,
      credentials: 'same-origin',
      body: JSON.stringify({
        query: mutation,
        variables
      })
    })
      .then(async (res) => {
        const { data: payload } = res.json ? await res.json() : res;

        if (payload.errors || payload.statusCode >= 400) {
          console.error(payload);
          return payload;
        }

        return payload?.data;
      })
      .catch((error) => {
        return error;
      });
  }
  const formData = new FormData();
  formData.append('query', mutation);
  formData.append('variables', JSON.stringify(variables));

  if (files) {
    for (const filename in files) {
      formData.append(filename, files[filename]);
    }
  }

  return fetch(endpoint, {
    method: 'post',
    headers,
    credentials: 'same-origin',
    body: formData
  }).then(parseJSON);
}

async function parseJSON(res) {
  const response = res?.data;
  if (response.errors) {
    console.error(response.errors);
    return response;
  }
  return response?.data || response;
}
