import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'ContestSubscriptions',
  title: 'ContestSubscriptions',
  category: 'Contest',
  exposes: [
    {
      title: 'contestSubscription',
      action: { module: 'ContestSubscriptions', props: { /* */ } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `module for subscriptions `,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
