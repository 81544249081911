import * as React from 'react';

const SvgAccessories = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 512 287.8"
    data-icon="1"
    {...props}
  >
    <path
      fillRule="nonzero"
      d="M116.46 138.58v-45.3c0-23.32 11.12-47.86 35.42-66.09 19.26-14.45 46.9-24.88 83.86-27.14L439.63 0l1.01.04c7.42-.05 14.18.53 20.29 1.67 17.4 3.24 30 10.89 38.43 21.4 8.45 10.55 12.42 23.66 12.63 37.87.09 5.82-.45 11.79-1.58 17.81-1.54 8.31-7.58 24.17-15.54 42.38-8.63 19.74-19.79 42.95-29.9 62.32-15.93 30.5-33.01 50.48-55.68 63.06-22.49 12.48-49.52 17.16-85.54 17.16H129.39c-2.11 0-4.11-.5-5.87-1.41H53.11v6.9c0 10.23-10.18 18.6-22.63 18.6H0V134.36h30.48c12.44 0 22.63 8.37 22.63 18.6v6.67c23.15 1.5 44.64-6.18 63.35-21.05zm259.93-43.09c-2.69-3.35-2.16-8.24 1.18-10.93a7.77 7.77 0 0 1 10.93 1.19l15.93 19.86c2.68 3.34 2.16 8.23-1.19 10.92-3.34 2.69-8.24 2.16-10.92-1.18l-15.93-19.86zm15.19-40.24c-2.67-3.34-2.12-8.22 1.22-10.9 3.34-2.67 8.22-2.12 10.89 1.22l41.34 51.69a7.754 7.754 0 0 1-1.21 10.9c-3.34 2.67-8.22 2.13-10.9-1.22l-41.34-51.69zM142.32 93.28v144.57h181.43c31.73 0 54.9-3.77 73.01-13.82 17.93-9.95 31.88-26.6 45.39-52.46 9.7-18.59 20.56-41.19 29.09-60.7 7.27-16.64 12.67-30.52 13.82-36.73.84-4.45 1.24-8.77 1.18-12.85-.13-8.56-2.33-16.21-6.98-22.02-4.69-5.85-12.19-10.2-22.98-12.21-4.58-.85-9.7-1.29-15.38-1.27l-1.27.07H236.5c-31.09 2.01-53.79 10.4-69.17 21.93-17.15 12.88-25.01 29.73-25.01 45.49z"
    />
  </svg>
);

export default SvgAccessories;
