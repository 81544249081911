import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  title: 'Sample',
  category: 'Dev',
  exposes: [
    {
      title: 'General',
      action: { module: 'Sample', props: { context: 'Window' } },
      desription: 'general-desc',
    },
    {
      title: 'UI',
      action: { module: 'Sample', props: { context: 'UI' } },
      desription: 'ui-desc',
    },
    {
      title: 'API',
      action: { module: 'Sample', props: { context: 'API' } },
      desription: 'api-desc',
    },
    {
      title: 'Form',
      action: { module: 'Sample', props: { context: 'Form' } },
      desription: 'form-desc',
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `A sample module that will try to use most features`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
