import { Accessories as Icon } from 'components/icons';

export const meta = {
  id: 'AccessoriesDetails',
  title: 'AccessoriesDetails',
  category: 'Vehicles',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `crud for accessory`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 680,
    height: 730
  }
};
