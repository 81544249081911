import { doQuery } from 'utils/graphql/';
import React, { createContext, useContext, useState, useEffect } from 'react';
import * as queries from './queries';

const TenantContext = createContext();

function TenantProvider({ children }) {
  // whatever you set here as default, will be used in both server side and client as well
  const [providerData, setProviderData] = useState({
    ready: false,
    data: {
      // add default data to your provider here
    }
  });

  const [locale, setLocale] = useState('');

  // you can get extra data asynchronously if needed
  // this will only affect the client side
  async function fetchTenantByLocale(locale) {
    try {
      const result  = await doQuery(queries.findByLocale, { locale });

      if (!result?.data?.tenantByLocale) {
        alert(`Failed to identify the tenant. Try reloading or report the problem.`);
        console.warn(result);
        return;
      }

      setProviderData(() => ({
        ...providerData,
        ...result.data.tenantByLocale,
        ready: true
      }));
    } catch (err) {
      console.log(err);
      throw err;
    }
  }


  useEffect(() => {
    if (locale) {
      fetchTenantByLocale(locale);
    }
  }, [locale]);

  const value = {
    ...providerData,
    setLocale
  };

  return (
    <TenantContext.Provider value={value}>
      {children}
    </TenantContext.Provider>
  );
}

// this allows you to use it as a simpler hook
function useTenant() {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within a TenantProvider');
  }

  return context;
}

export { TenantProvider, TenantContext, useTenant };
