import getConfig from 'next/config';
import goFetch from 'isomorphic-unfetch';
import Cors from 'cors';

const { publicRuntimeConfig } = getConfig();

export async function fetch(url, options) {
  const finalUrl = url.startsWith('http')
    ? url
    : publicRuntimeConfig.SERVER_ADDR
      .replace(/\/$/, '') + '/' + url.replace(/^(\.|\/)/g, '');

  return new Promise((resolve, reject) => {
    goFetch(finalUrl, options)
      .then(async data => {
        resolve({ data: await data.json() });
      })
      .catch(error => {
        resolve({error});
      });
  });
}

// Helper method to wait for a middleware to execute before continuing
// And to throw an error when an error happens in a middleware
function runMiddleware(req, res, fn) {
  return new Promise((resolve, reject) => {
    fn(req, res, (result) => {
      if (result instanceof Error) {
        return reject(result);
      }

      return resolve(result);
    });
  });
}

export function initMiddleware(middleware) {
  return (req, res) =>
    new Promise((resolve, reject) => {
      middleware(req, res, (result) => {
        if (result instanceof Error) {
          return reject(result);
        }
        return resolve(result);
      });
    });
}

export async function applyCors(req, res, options) {
  const cors = Cors(options);

  return await runMiddleware(req, res, cors);
}

export function send (res, data = {}, status = 200) {
  res.statusCode = status;
  try {
    res.setHeader('Content-Type', 'application/json');
  } catch (error) {
    // do nothing
  }
  res.end(JSON.stringify(data));
}

export async function fetchJson(...args) {
  try {
    const response = await goFetch(...args);
    const contentType = response.headers.get('content-type');

    let data;
    if (contentType && contentType.indexOf('application/json') !== -1) {
      data = await response.json();
    } else {
      data = await response.text();
    }

    if (response.ok) {
      return data;
    }

    const error = new Error(response.statusText);
    error.response = response;
    error.data = data;
    throw error;
  } catch (error) {
    if (!error?.data) {
      error.data = { message: error.message };
    }
    throw error;
  }
}

/**
 * Create a fetch request with a timeout, based on AbortController and AbortSignal
 *
 * @param {string} url Endpoint
 * @param {integer} ms Timeout in miliseconds
 * @param {*} param2 Custom fetch parameters
 * @returns Promise with a timeout
 */
export function fetchTimeout(url, ms = 5000, { signal, ...options } = {}) {
  const finalUrl = url.startsWith("http")
    ? url
    : publicRuntimeConfig
      .SERVER_ADDR
      .replace(/\/$/, '') + '/' + url.replace(/^(\.|\/)/g, '');

  const controller = new AbortController();
  const promise = fetch(finalUrl, { signal: controller.signal, ...options });
  if (signal) {
    signal.addEventListener("abort", () => controller.abort());
  }

  const timeout = setTimeout(() => {
    console.error(`ERRO: Timed out request after ${ms} miliseconds.`);
    return controller.abort();
  }, parseInt(ms, 10));

  return promise
    .then(async res => {
      const status = parseInt(res.status, 10);
      const contentType = res.headers.get('content-type');

      let body;
      if (contentType && contentType.indexOf('application/json') !== -1) {
        body = await res.json();
      } else {
        body = await res.text();
      }

      if (status >= 400) {
        // Ignora do log erro normal de expiração de token
        if (body?.error !== 'invalid_token') {
          console.error(
            `Fetchtimeout Error: Response from '${url}': ${res.statusText}`
          );
          console.info(`Content-Type da resposta: '${contentType}'`);
          console.info(res);
          console.debug(body);
        }

        throw new Error(body?.error || body || res.statusText);
      }

      return body;
    })
    .finally(() => clearTimeout(timeout));
}

export default fetch;
