import {
  CarVersion as Icon
} from 'components/icons';

export const meta = {
  id: 'VehiclesDetails',
  title: 'VehiclesDetails',
  category: 'Vehicles',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `Module to manage vehicles`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 740,
    height: 630
  }
};
