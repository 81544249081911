import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'OffersDetails',
  title: 'OffersDetails',
  category: 'Offers',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `crud for offers`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 700,
    height: 730
  }
};
