import {
  Info as Icon
} from 'components/icons';

export const meta = {
  id: 'EventLocals',
  title: 'EventLocals',
  category: 'Event',
  exposes: [
    {
      title: 'event local',
      action: { module: 'EventLocals', props: { /* */ } },
    },
    {
      title: 'New event local',
      action: { module: 'EventLocalDetails', props: { context: 'add' } },
    },
  ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `module to register the event`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
