import * as React from 'react';

const SvgLayouts = (props) => (
  <svg
    className="layouts_svg__MuiSvgIcon-root layouts_svg__MuiSvgIcon-fontSizeMedium layouts_svg__MuiBox-root layouts_svg__css-uqopch"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="AutoAwesomeMosaicOutlinedIcon"
    data-icon="1"
    {...props}>
    <path d="M3 5v14a2 2 0 0 0 2 2h6V3H5a2 2 0 0 0-2 2zm6 14H5V5h4v14zM19 3h-6v8h8V5c0-1.1-.9-2-2-2zm0 6h-4V5h4v4zm-6 12h6c1.1 0 2-.9 2-2v-6h-8v8zm2-6h4v4h-4v-4z" />
  </svg>
);

export default SvgLayouts;
