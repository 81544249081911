import {
  MapOutlined as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'AddressPicker',
  title: 'AddressPicker',
  category: 'Utils',
  exposes: [
    {
      title: 'Check an address',
      action: { module: 'AddressPicker', props: { /* */ } },
      desription: 'general-desc',
    },
  ],
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `A picker that allows you to select a region or specific address.`,
  windowOptions: {
    resizable: false,
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 530,
    height: 456
  }
};
