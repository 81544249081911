import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'BlockManagement',
  title: 'BlockManagement',
  category: 'Pages',
  // exposes: [
  //   {
  //     title: 'General',
  //     action: { module: 'BlockManagement', props: { /* */ } },
  //     desription: 'general-desc',
  //   },
  // ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `Here we will manage the page blocks`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    // width: 630,
    // height: 380
  }
};
