import { gql } from 'utils/graphql/';

const upsertUserSettings = gql`
  mutation($data: UpsertUserSettingsInput!) {
    upsertUserSettings(data: $data) {
      id
    }
  }
`;

export { upsertUserSettings };
