import * as React from 'react';

const SvgCalendarMonthOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="CalendarMonthOutlined_svg__MuiSvgIcon-root CalendarMonthOutlined_svg__MuiSvgIcon-fontSizeMedium CalendarMonthOutlined_svg__MuiSvgIcon-fontSizeLarge CalendarMonthOutlined_svg__css-1shn170"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="CalendarMonthOutlinedIcon"
    tabIndex={-1}
    data-icon="1"
    {...props}
  >
    <path
      d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"
      fill="#FFF"
    />
  </svg>
);

export default SvgCalendarMonthOutlined;
