import {
  VehicleCategories as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'VehicleCategoriesDetails',
  title: 'VehicleCategoriesDetails',
  category: 'Vehicles',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `Module to manage categories`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 360,
    height: 300
  }
};
