import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'DealerAddressesDetails',
  title: 'DealerAddressesDetails',
  category: 'dealer',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `module for dealer address`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 540,
    height: 610
  }
};
