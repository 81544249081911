import * as React from 'react';

const SvgDirectionsCarOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="DirectionsCarOutlined_svg__MuiSvgIcon-root DirectionsCarOutlined_svg__MuiSvgIcon-fontSizeMedium DirectionsCarOutlined_svg__MuiSvgIcon-fontSizeLarge DirectionsCarOutlined_svg__css-1shn170"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="DirectionsCarOutlinedIcon"
    tabIndex={-1}
    data-icon="1"
    {...props}
  >
    <path
      d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.85 7h10.29l1.08 3.11H5.77L6.85 7zM19 17H5v-5h14v5z"
      fill="#FFF"
    />
    <circle cx={7.5} cy={14.5} r={1.5} fill="#FFF" />
    <circle cx={16.5} cy={14.5} r={1.5} fill="#FFF" />
  </svg>
);

export default SvgDirectionsCarOutlined;
