import React from 'react';
import i18n from 'utils/i18n';
// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule CookiesBanner
 * 
 */
function CookiesBanner (props) {
  const t = i18n.useTranslations('components.cookie_consent');
  const {
    className = "",
    onAccept = () => null,
    onDeny = () => null,
    children,
    ...other
  } = props;

  return <div
    className={`${css["molecule__cookies-banner-container"]} ${className}`}
    {...other}
  >
    <div className={css['content']}>
      <div>{t('message')}</div>
      <a href={t('href')}>{t('link')}</a>
      <br />
      <button onClick={onDeny} data-something="1">{t('deny')}</button>
      <button onClick={onAccept}>{t('allow')}</button>
    </div>
  </div>;
}

export default CookiesBanner;
