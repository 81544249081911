import React, { useState, useEffect } from 'react';
import { useAnalytics } from 'providers/analytics';
import { useRouter } from "next/router" ;
import css from './styles.module.scss';
import CookiesBanner from 'components/molecules/cookies-banner';
import Transition from "utils/hocs/transition" ;
import {enableDarkThemeFromOS} from '__config/styleguide/colors';

const Layout = ({children}) => {
  const router = useRouter();
  const { analytics, accept, deny } = useAnalytics();
  const [
    cookiesPermissionsModalOpen,
    setCookiesPermissionsModalOpen
  ] = useState(false);

  useEffect(_ => {
    if (!analytics.ready) { return; }

    if (analytics.allowed || analytics.shown) {
      setCookiesPermissionsModalOpen(false);
    } else {
      setCookiesPermissionsModalOpen(true);
    }
  }, [analytics]);

  return (
    <div
      className={css["layout"]}
      data-theme={enableDarkThemeFromOS ? 'os' : 'light'}
    >
      <Transition location={router.pathname}>
        <main className={css["main"]}>
          {children}
        </main>
      </Transition>

      {
        cookiesPermissionsModalOpen && (
          <CookiesBanner onAccept={accept} onDeny={deny} />
        )
      }

    </div>
  );
};

export default Layout;