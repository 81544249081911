import React from 'react';
import i18nOptions from '__config/i18n';

// NOTE: This file is changed by the `yarn creator` command.
// Do not remove any of the !IMPORTANT! comments

// !IMPORTANT! - Importing contexts
import { NextIntlProvider, IntlErrorCode } from 'next-intl';
import { AnalyticsProvider } from 'providers/analytics';
import { WindowsProvider } from 'providers/windows';
import { UserSettingsProvider } from 'providers/user-settings';
import { UiProvider } from 'providers/ui';
import { ContextMenuProvider } from 'providers/context-menu';
import { AuthProvider } from 'providers/auth';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { TenantProvider } from 'providers/tenant';
import { ProductProvider } from 'providers/product';
// !IMPORTANT! - End of importing contexts // do not remove this comment

export function withContext(Provider, providerData, Component) {
  return <Provider {...providerData}>{Component}</Provider>;
}

export function withContexts (comp, arr) {
  for (let i = 0; i < arr.length; i++ ) {
    comp = withContext(arr[i][0], arr[i][0], comp);
  }
  return comp;
}

function applyContexts(comp, data) {

  /* eslint-disable max-len */
  // !IMPORTANT! - Using the list of contexts. The order of them matter here.
  comp = withContext(AnalyticsProvider, {}, comp);
  comp = withContext(WindowsProvider, {}, comp);
  comp = withContext(UserSettingsProvider, {}, comp);
  comp = withContext(UiProvider, {}, comp);
  comp = withContext(ContextMenuProvider, {}, comp);
  comp = withContext(AuthProvider, {}, comp);
  comp = withContext(UserProvider, {}, comp);
  comp = withContext(TenantProvider, {}, comp);
  comp = withContext(ProductProvider, {}, comp);
  // !IMPORTANT! - End of using contexts // do not remove this comment
  /* eslint-enable max-len */

  // let's add i18n as the last one, so other contexts can use it too
  comp = withContext(NextIntlProvider, {
    onError (error) {
      // will be treated by the callback
    },

    getMessageFallback ({namespace, key, error}) {
      const path = [namespace, key].filter((part) => part != null).join('.');

      const showMessages = i18nOptions.verbosity === "0";

      if (error.code === IntlErrorCode.MISSING_MESSAGE) {
        showMessages && console.info(`[i18n] :: ${path} is not yet translated`);
        return i18nOptions.defaultFallback === '*'
          ? key
          : i18nOptions.defaultFallback || '';
      } else {
        showMessages && console.error(
          `[i18n] :: Error with "${path}", please check its format.`,
          error
        );
        return `[i18n] :: Error with "${path}"`;
      }
    },
    now: new Date(),
    locale: (data?.pageProps?.messages)
      ? (data?.pageProps.locale || undefined) // undefined will use default locale
      : i18nOptions.defaultLocale,
    messages: data?.pageProps?.messages,
    ...i18nOptions
  }, comp);

  return comp;
}

export {
  applyContexts
};
