import { Accessories as Icon } from 'components/icons';

export const meta = {
  id: 'AccessoryCategoriesDetails',
  title: 'AccessoryCategoriesDetails',
  category: 'Vehicles',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `category for accessories`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 440,
    height: 410
  }
};
