import {
  Info as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'ContentsDetails',
  title: 'ContentsDetails',
  category: 'Pages',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `The content manager. Contents are used in multiple places of the application.`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 880,
    height: 630
  }
};
