/**
 * Use the structure:
 * colors: {
 *   category: {
 *     name: [lightColor, darkColor],
 *     name2: [lightColor, darkColor],
 *     name3: color, // used in both light and dark themes
 *   }
 * }
 */

module.exports = {
  // if true, this flag will activate dark mode automatically if the user has their OS in Dark mode
  enableDarkThemeFromOS: false,
  colors: {
    primary: {
      alpha   : ['#E8152799'],
      lightest: ['#e98d95'],
      lighter : '#f07983',
      light   : '#ec5360',
      default : ['#E81527'],
      dark    : '#bc1321',
      darker  : '#97101b',
      darkest : '#6d0e16',
      disabled: '#FF5B6B',
    },
    secondary: {
      alpha   : ['#f7ae28bb'],
      lightest: '#fff1d6',
      lighter : '#ffe1a9',
      light   : '#fdce77',
      default : '#f7ae28',
      dark    : '#e3a127',
      darker  : '#c18923',
      darkest : '#9d701e',
      disabled: '#fff',
    },
    state: {
      success: ['#8cf5c6', '#15955c'],
      waiting: '#5F6A71',
      info   : ['#7289DA', '#496ae2'],
      warn   : ['#FAA618', '#da8a03'],
      danger : '#F14746',
    },
    base: {
      text    : ['#222', '#f0f0f0'],
      link    : '#0D8BF2',
      bg      : ['#fbfbfb', '#333'],
      bgFallback : ['#000c', '#fffb'],
      alpha   : ['#0008'],
      lightest: '#fff',
      lighter : '#f9f9f9',
      light   : '#f0f0f0',
      default : '#d0d0d0',
      dark    : '#999',
      darker  : '#777',
      darkest : '#000',
    },
  },
  matches: {
    headbar  : ['base-text', 'primary'],
    highlight: ['base-text', 'secondary'],
    success  : ['base-text', 'state-success'],
    danger   : ['base-lightest', 'state-danger'],
    warn     : ['base-text', 'state-warn'],
    info     : ['base-text', 'state-info'],
  }
};
