import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';
import Script from 'next/script';

const AnalyticsContext = createContext();

function AnalyticsProvider({ children }) {
  // whatever you set here as default, will be used as server side as well
  const [providerData, setProviderData] = useState({
    ready: false,
    data: {
      gtagId: process.env.GA_TRACKING_ID
    }
  });

  function fetchProviderData () {
    //! TODO: Add your code here to fetch data to fill in the provider
    const cookiesStatus = localStorage.getItem('allowedCookiesStatus');
    setProviderData({
      ...providerData,
      ready: true,
      allowed: cookiesStatus === '1' ? true : false,
      // set (key, value) {
      //   setProviderData({
      //     ...providerData,
      //     [key]: value
      //   });
      // }
    });
  }

  // once it loads in the client, we go ahead and fetch the data
  useEffect(() => {
    fetchProviderData();
  }, []);

  useEffect(_ => {
    if (!providerData.ready || !providerData.allowed) { return; }

    // store it in the localStorage to avoid asking again
    localStorage.setItem('allowedCookiesStatus', '1');


  }, [providerData.allowed]);

  const value = {
    analytics: providerData,
    accept () {
      setProviderData({
        ...providerData,
        allowed: true
      });
    },
    deny () {
      setProviderData({
        ...providerData,
        allowed: false,
        shown: true,
      });
    }
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}

      {
        providerData.ready &&
        providerData.allowed &&
        providerData.data.gtagId && <>
          <Script
            id="gtag-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              /* eslint-disable max-len */
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${providerData.data.gtagId}');
              `,
              /* eslint-enable max-len */
            }}
          />
        </>

        // TODO: Add support for facebook and other tag managers/analytics here
      }
    </AnalyticsContext.Provider>
  );
}

function useAnalytics () {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return context;
}

export {
  AnalyticsProvider,
  AnalyticsContext,
  useAnalytics
};
