import {
  ColorLensOutlined as Icon // TODO: select an icon
} from 'components/icons';

export const meta = {
  id: 'ColorDetails',
  title: 'ColorDetails',
  category: 'Vehicles',
  unique: false,
  icon: Icon,
  version: '1.0.0',
  description: `The window to manage a single color, or to see its details.`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 440,
    height: 330
  }
};
