import * as React from 'react';

const SvgSignpostOutlined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="SignpostOutlined_svg__MuiSvgIcon-root SignpostOutlined_svg__MuiSvgIcon-fontSizeMedium SignpostOutlined_svg__MuiSvgIcon-fontSizeLarge SignpostOutlined_svg__css-1shn170"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="SignpostOutlinedIcon"
    tabIndex={-1}
    data-icon="1"
    {...props}
  >
    <path
      d="M13 10h5l3-3-3-3h-5V2h-2v2H4v6h7v2H6l-3 3 3 3h5v4h2v-4h7v-6h-7v-2zM6 6h11.17l1 1-1 1H6V6zm12 10H6.83l-1-1 1-1H18v2z"
      fill="#FFF"
    />
  </svg>
);

export default SvgSignpostOutlined;
