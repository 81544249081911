import { doMutation, doQuery } from 'utils/graphql';
import * as queries from './queries';

import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';

import {
  debounce,
} from 'utils/functions';

import { upsertUserSettings } from './mutations';

const UserSettingsContext = createContext();

function UserSettingsProvider ({ children }) {
  // whatever you set here as default, will be used in both server side and client as well
  const [providerData, setProviderData] = useState({
    ready: false,
    data : {
      // add default data to your provider here
      customizations: {
        wallpaper: '9',
        theme: 'dark',
      },
    }}
  );

  // you can get extra data asynchronously if needed
  // this will only affect the client side
  async function fetchProviderData () {
    const result  = await doQuery(queries?.userSettings);
    let settings = result?.data?.userSettings || {
      customizations : {}
    };


    // if has no product select the first product
    if (!settings?.customizations?.selectedProduct) {
      const queryProductsRes = await doQuery(queries?.findAllProducts);
      settings.customizations.selectedProduct =
        queryProductsRes?.data?.products[0];
    }


    setProviderData({
      ...providerData,
      data : {
        ...settings,
      },
      ready: true,
    });
  }

  useEffect(() => { fetchProviderData().catch((err) => {
    console.log(err);
  }); }, []);

  useEffect(() => {
    const disableAnimations = providerData?.data?.customizations?.reducedMotion;
    document.body.dataset.reducedMotion = disableAnimations ? 1 : 0;
  }, [providerData?.data?.customizations?.reducedMotion]);

  useEffect(() => {
    const disableBlur = providerData?.data?.customizations?.reducedBlur;
    document.body.dataset.reducedBlur = disableBlur ? 1 : 0;
  }, [providerData?.data?.customizations?.reducedBlur]);

  const value = {
    ...providerData,
    fetchData : fetchProviderData,
    setData (key, value) {
      const newData = {
        ...providerData,
        data: {
          ...providerData?.userSettings,
          [key]: value
        }
      };
      setProviderData(newData);
      sendUserSettings(newData);
    }
    // TODO: add more methods/actions here
  };

  return (
    <UserSettingsContext.Provider value={value}>

      {children}

      {/*
        //! you can add extra components here
       */}
    </UserSettingsContext.Provider>
  );
}

const sendUserSettings = debounce(async (newData) => {
  await doMutation(upsertUserSettings, newData);
},500);

// this allows you to use it as a simpler hook
function useUserSettings () {
  const context = useContext(UserSettingsContext);
  if (context === undefined) {
    throw new Error(`useUserSettings must be used within a UserSettingsProvider`);
  }

  return context;
}

export {
  UserSettingsProvider,
  UserSettingsContext,
  useUserSettings,
};
