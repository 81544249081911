import {
  VehicleCategories as Icon
} from 'components/icons';

export const meta = {
  id: 'VehicleCategories',
  title: 'VehicleCategories',
  category: 'Vehicles',
  // exposes: [
  //   {
  //     title: 'VehicleCategory',
  //     action: { module: 'VehicleCategories', props: { /* */ } },
  //   },
  //   {
  //     title: 'New VehicleCategory',
  //     action: { module: 'VehicleCategoriesDetails', props: { context: 'add' } },
  //   },
  // ],
  unique: true,
  icon: Icon,
  version: '1.0.0',
  description: `Module to manage categories`,
  windowOptions: {
    // if not passed, will be centered
    // x: null,
    // y: null,
    width: 480,
    height: 450
  }
};
